@import url(http://fonts.googleapis.com/earlyaccess/nanumgothic.css);

.App {
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #222;
}

.table td, .table th {
  padding: 0.4rem 0.5rem
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eee;
}

.uid-style {
  max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pagination {
  justify-content: center;
  margin-top: 30px;
}

.pagination li {
  display: inline-block;
  padding: 2px 5px;
  text-align: center;
  border-radius: 7px;
  cursor: pointer;
  margin: 0px 2px;
  font-size: 0.9em;
}

.pagination .active {
  font-weight: bolder;
}

.pagination .disabled {
  cursor: default;
  background-color: #eee;
  color: #AAB;
  border: 1px solid #aaa;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

